import React, { useState, useContext } from "react";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import notifier from "simple-react-notifications";

import { LoadingContext } from "../App";

const qs = require("query-string");

function ResetiranjeZaporke(props) {
  const urlParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });

  const { setLoading } = useContext(LoadingContext);
  const [redirect, setRedirect] = useState(false);

  const [formData, setFormData] = useState({ zaporka: "" });
  const createFormChange = event => {
    const target = event.target;
    setFormData({ ...formData, [target.name]: target.value });
  };
  const handleFormSubmit = event => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API_URL}/reset/${urlParams.token}`,
        formData
      )
      .then(function(response) {
        setLoading(false);
        if (response.data.ok) {
          notifier.success("Uspješno ste promjenili lozinku.");
          setRedirect(true);
        } else {
          notifier.error(response.data.msg);
        }
      })
      .catch(function(error) {
        setLoading(false);
        console.log(error);
        notifier.error(
          `Dogodila se greška prilikom resetiranja lozinke. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${process.env.REACT_APP_EMAIL}.`
        );
      });
  };

  if (redirect) {
    return <Redirect to="/prijava" />;
  }

  return (
    <article className="cijene-emailom-obrazac">
      <h2>Nova lozinka</h2>
      <form className="cijene-mailom" onSubmit={handleFormSubmit}>
        <div className="form-items">
          <div className="form-item">
            <input
              type="password"
              id="zaporka"
              name="zaporka"
              placeholder="Lozinka"
              // pattern=".+@globex.com"

              required
              value={formData.zaporka}
              onChange={createFormChange}
            />
            <label htmlFor="email">Lozinka</label>
          </div>
        </div>
        <div className="form-submit">
          <input type="submit" value="Nova lozinka" />
        </div>
      </form>
    </article>
  );
}

export default withRouter(ResetiranjeZaporke);
