import React, { useEffect } from "react";

function Info(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main-content" className="info-stranica">
      <article className="projekt-opis">
        <div className="projekt-tekst">
          <h2>Upute za korištenje</h2>
          <p>
            1. Postaje možete pretraživati i prema njihovim dodatnim uslugama.
            Kliknite Detaljno filtriranje ispod mape i pronađite postaje s
            dodatnim karakteristikama, ponudama i uslugama
          </p>
          <p>
            2. Ukoliko želite primati obavijesti o cijenama goriva na email,
            prijavite se pomoću forme na dnu naslovne stranice
          </p>
          <p>
            3. Stranicu je preporučljivo pregledavati pomoću preglednika Chrome,
            Safari, Firefox ili novije verzije Internet Explorer (10 i 11)
          </p>
          <p>
            4. Na stranici svake postaje možete vidjeti trend kretanja cijena
            osnovnih vrsta goriva
          </p>
        </div>
        <img className="projekt-slika" src="/min2goTema/img/upute.jpg" alt="" />
      </article>
    </main>
  );
}

export default Info;
