import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "react-modal";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  ViberIcon,
  ViberShareButton
} from "react-share";

import { CoordsContext, MapContext } from "../App";
import { getCurrentDay, getCurrentTime } from "./tmp/util";
import { createAddOpenConsumer } from "./tmp/postajaConsumers";
import MapComponent from "./Map";
import PostajaGrafikon from "./PostajaGrafikon";
import { error } from "./tmp/geolocationUtil";
import GorivaPostaje from "../widgets/GorivaPostaje";
import { getDistanceAndTimeOfTwoLocations } from "./RoutingMachine";

function PostajaStranica(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [postaja, setPostaja] = useState(props.postaja);
  const [distanceAndDuration, setDistanceAndDuration] = useState();
  const { coords, setCoords } = useContext(CoordsContext);

  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   const { postaja } = props;

  //   const userLoc = `${coords.longitude},${coords.latitude}`;
  //   const postajaLoc = `${postaja.lat},${postaja.long}`;
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_OSRM_TABLE_URL}${userLoc};${postajaLoc}?sources=0&destinations=1`
  //     )
  //     .then(function(response) {
  //       if (response.status !== 200) {
  //         return;
  //       }
  //       const data = response.data;
  //       console.log(data);
  //       if (data.code !== "Ok") {
  //         return;
  //       }
  //       setDistanceAndDuration({
  //         duration: data.durations[0][0],
  //         distance: data.destinations[0].distance
  //       });
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });

  //   setPostaja(postaja);
  // }, []);

  const map = useContext(MapContext);
  useEffect(() => {
    if (!coords) {
      return;
    }

    let didCancel = false;

    getDistanceAndTimeOfTwoLocations(
      { long: postaja.long, lat: postaja.lat },
      { lat: coords.latitude, lng: coords.longitude },
      map,
      e => {
        if (didCancel) {
          return;
        }

        var routes = e.routes;
        var summary = routes[0].summary;
        // var km = Math.round(summary.totalDistance / 1000);
        // console.log(summary.totalTime);
        // var h = Math.floor(summary.totalTime / 3600);
        // var min = Math.round((summary.totalTime % 3600) / 60);
        // console.log((h !== 0 ? `${h}h ` : "") + `${min} min`);
        setDistanceAndDuration({
          duration: summary.totalTime,
          distance: summary.totalDistance
        });
      }
    );

    return () => {
      didCancel = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const coords = useContext(CoordsContext);
  // const [postaja, setPostaja] = useState();
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   axios
  //     .get(`http://localhost:5000/api/postaje/${id}`)
  //     .then(response => {
  //       postaja = response.data;
  //       postaja.cjeniks = postaja.cjenici;
  //       postaja.radno_vrijemes = postaja.radnaVremena;
  //       postaja.opcijas = postaja.opcije;
  //       setPostaja(postaja);
  //       setLoading(false);
  //     })
  //     .catch(error => console.log(error));
  // }, []);

  // if (loading) {
  //   return <Spinner />;
  // }

  const currentDay = getCurrentDay();
  const currentTime = getCurrentTime();
  createAddOpenConsumer(currentDay, currentTime)(postaja);

  let dataPostajaRv, postajaRvDt, className;
  dataPostajaRv = postajaRvDt = "Zatvoreno";
  className = "postaja-rv-zatvoreno";
  if (postaja.open) {
    dataPostajaRv = postajaRvDt = "Otvoreno";
    className = "postaja-rv";
  }

  const handleClickOdvediMe = e => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
      window.location = "#leaflet-map";
    } else {
      /* geolocation IS NOT available */
    }
  };

  const success = position => {
    setCoords({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude + (1 + Math.random()) * 1e-6
    });
  };

  postaja.cijeneGoriva = postaja.cjeniks;

  let distance;
  let duration;
  if (distanceAndDuration) {
    const km = Math.round(distanceAndDuration.distance / 1000);
    const m = Math.round(distanceAndDuration.distance);
    distance = km !== 0 ? `${km} km ` : `${m} m `;
    var h = Math.floor(distanceAndDuration.duration / 3600);
    var min = Math.round((distanceAndDuration.duration % 3600) / 60);
    duration = "(" + (h !== 0 ? `${h}h ` : "") + `${min} min` + ")";
  }

  Modal.setAppElement("#root");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };

  // const url = "http://mzoe-gor.hr";
  const url = window.location.href;

  return (
    <main id="main-content" className="postaja-stranica">
      <h1>{postaja.naziv}</h1>
      <article>
        <section className="postaja">
          <dl className={className}>
            <span>Radno vrijeme: </span>
            {postaja.radnoVrijemesDanas &&
              postaja.radnoVrijemesDanas.map(rv => (
                <dd key={rv.id}>
                  <time key={1}>{rv.pocetak.slice(0, 5)}</time>–
                  <time key={2}>{rv.kraj.slice(0, 5)}</time>
                </dd>
              ))}
            <dt>{postajaRvDt}</dt>
          </dl>
          {postaja.obveznik.logo ? (
            <img
              className="logo-obveznika-postaja sredina"
              src={
                process.env.REACT_APP_LOGO_URL_PREFIX + postaja.obveznik.logo
              }
              alt={postaja.obveznik.naziv}
            ></img>
          ) : (
              <h6 className="mala-margina text-center">
                {postaja.obveznik.naziv}
              </h6>
            )}
          <address className="postaja-lokacija">
            <ul>
              <li>{postaja.adresa + ", " + postaja.mjesto}</li>
              <li className="postaja-proximity">
                {distance}
                <small>
                  <time dateTime="PT14M">{duration}</time>
                </small>
              </li>
            </ul>
          </address>
          <GorivaPostaje postaja={postaja} />
          {/* <ul className="postaja-cijene">
            {postaja.cjeniks.map(cjenik => (
              <li
                key={cjenik.id}
                data-postaja-gorivo={
                  cjenik.gorivo.oznaka ? cjenik.gorivo.oznaka.toLowerCase() : ""
                }
                data-postaja-unit="kn/L"
              >
                {cjenik.cijena}
                <span className="postaja-cijena-unit">kn/L</span>
              </li>
            ))}
          </ul> */}
          <div className="postaja-links">
            <button className="postaja-odvedi" onClick={handleClickOdvediMe}>
              Odvedi me
            </button>
            <button
              className="postaja-share"
              onClick={() => setModalIsOpen(true)}
            >
              Podijeli
            </button>
            <Modal
              isOpen={modalIsOpen}
              // onAfterOpen={this.afterOpenModal}
              onRequestClose={() => setModalIsOpen(false)}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <EmailShareButton
                url={url}
                subject={postaja.naziv + " moez-gor.hr "}
                body={postaja.naziv + "\n " + postaja.obveznik.naziv}
                separator={"\n\n"}
              >
                <EmailIcon />
              </EmailShareButton>
              <FacebookShareButton
                url={url}
                quote={postaja.naziv + "\n " + postaja.obveznik.naziv}
                hashtag={"#mzoegor"}
              >
                <FacebookIcon />
              </FacebookShareButton>
              <TwitterShareButton
                url={url}
                title={postaja.naziv + "\n " + postaja.obveznik.naziv}
                hashtags={["#mzoegor"]}
              >
                <TwitterIcon />
              </TwitterShareButton>
              <WhatsappShareButton
                url={url}
                title={postaja.naziv + "\n " + postaja.obveznik.naziv}
                separator={"\n"}
              >
                <WhatsappIcon />
              </WhatsappShareButton>
              {/* <ViberShareButton url={url} title={""} separator={""}>
                <ViberIcon />
              </ViberShareButton> */}
            </Modal>
          </div>
        </section>

        <section className="postaja-opcije">
          <h2>Opcije postaje</h2>
          {postaja.opcijas.length === 0 && (
            <p>
              <span className="nije-uneseno">X </span>Nije unešeno
            </p>
          )}
          <ul>
            {postaja.opcijas.map(opcijaPostaje => (
              <li
                key={opcijaPostaje.id}
                className={"op-" + opcijaPostaje.opcija.id}
              >
                {opcijaPostaje.opcija.naziv}
              </li>
            ))}
          </ul>
          {postaja.obavijests.map(obavijest => {
            return (
              <div key={obavijest.id} className="postaja-napomena">
                {obavijest.obavijest}
              </div>
            );
            // return (
            //   <div key={obavijest.id} className="postaja-napomena">
            //     zatvoreno <time dateTime="2019-02-23">23/02</time> zbog punjenja
            //   </div>
            // );
          })}
          {/* <div className="postaja-napomena">
            zatvoreno <time dateTime="2019-02-23">23/02</time> zbog punjenja
          </div> */}
        </section>

        <MapComponent postaja={postaja} coords={coords} />
        <PostajaGrafikon postajaId={postaja.id} />
      </article>
    </main>
  );
}

export default PostajaStranica;
