import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { withLeaflet } from "react-leaflet";
import { getPostajaIcon } from "./tmp/icons";

class Routing extends MapLayer {
  constructor(props) {
    super(props);
  }

  createLeafletElement() {
    const { map } = this.props;
    let leafletElement = L.Routing.control({
      waypoints: [
        L.latLng(this.props.coords.latitude, this.props.coords.longitude),
        L.latLng(this.props.postaja.long, this.props.postaja.lat)
      ],
      createMarker: (i, waypoint, n) => {
        if (i === 1) {
          return L.marker(waypoint.latLng, {
            icon: getPostajaIcon(this.props.postaja, true)
          });
        } else {
          return L.marker(waypoint.latLng);
        }
      },
      router: new L.Routing.OSRMv1({
        serviceUrl: process.env.REACT_APP_OSRMv1_URL
      }),
      lineOptions: {
        styles: [
          {
            color: "blue",
            opacity: 0.6,
            weight: 4
          }
        ]
      },
      addWaypoints: false,
      draggableWaypoints: false,
      // fitSelectedRoutes: false,
      showAlternatives: false,
      show: false
    }).addTo(map.leafletElement);
    // leafletElement.on("routesfound", function(e) {
    //   var routes = e.routes;
    //   var summary = routes[0].summary;
    //   // alert distance and time in km and minutes
    //   console.log(
    //     "Total distance is " +
    //       summary.totalDistance / 1000 +
    //       " km and total time is " +
    //       Math.round((summary.totalTime % 3600) / 60) +
    //       " minutes"
    //   );
    // });
    return leafletElement.getPlan();
  }
}
export default withLeaflet(Routing);

export function getDistanceAndTimeOfTwoLocations(
  postaja,
  userCoords,
  map,
  routesfoundCallback
) {
  const control = L.Routing.control({
    waypoints: [
      L.latLng(postaja.long, postaja.lat),
      L.latLng(userCoords.lat, userCoords.lng)
    ],
    router: new L.Routing.OSRMv1({
      serviceUrl: process.env.REACT_APP_OSRMv1_URL
    })
  });
  control.addTo(map);
  control.on("routesfound", routesfoundCallback);
  control.route();
}
