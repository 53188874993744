import React from "react";

function ErrorComponent(props) {
  let subject = props.subject;
  if (subject === undefined) {
    const email = process.env.REACT_APP_EMAIL;
    subject = `Probajte ponovno učitati stranicu. U slučaju da i dalje imate problema, javite nam na e-adresu ${email}.`;
  }
  return (
    <div className="error-component">
      <h1 style={props.titleStyle}>
        <img src="./min2goTema/img/exclamation.png" />
        &nbsp;
        {props.title}
      </h1>
      <p style={props.subjectStyle}>{subject}</p>
    </div>
  );
}

export default ErrorComponent;
