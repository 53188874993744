import React from "react";
import Postaja from "./Postaja";
import ErrorComponent from "./ErrorComponent";

function TrazilicaRezultatiOtvorene(props) {
  const { postajas, onMouseEnter, onMouseLeave } = props;
  const openPostajas = [];
  postajas.some(postaja => {
    if (postaja.open) {
      openPostajas.push(postaja);
    }
    return !postaja.open;
  });
  const closedPostajas = postajas.slice(openPostajas.length);

  if (openPostajas.length === 0 && closedPostajas.length === 0) {
    return (
      <section
        className="trazilica-rezultati-otvorene"
        style={{ height: "auto" }}
      >
        <ErrorComponent
          subject={
            "Na ovom području nema postaja. Pokušajte povećati doseg ili potražiti drugu lokaciju."
          }
          subjectStyle={{ fontSize: "14px", marginBottom: "40px" }}
          titleStyle={{ marginTop: "20px" }}
        />
      </section>
    );
  }

  return (
    <section className="trazilica-rezultati-otvorene">
      {openPostajas.length !== 0 && <h2>Otvorene postaje</h2>}
      {openPostajas.map(postaja => (
        <Postaja
          key={postaja.id}
          postaja={postaja}
          showDetails={false}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      ))}
      {closedPostajas.length !== 0 && <h2>Zatvorene postaje</h2>}
      {closedPostajas.map(postaja => (
        <Postaja
          key={postaja.id}
          postaja={postaja}
          showDetails={false}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      ))}
    </section>
  );
}

export default TrazilicaRezultatiOtvorene;
