import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { DataContext } from "../App";
import Grafikon from "./Grafikon";
import ErrorComponent from "./ErrorComponent";

function PostajaGrafikon(props) {
  const { postajaId } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState();
  const [series, setSeries] = useState();
  const [legendNames, setLegendNames] = useState();
  const { gorivos_obj } = useContext(DataContext);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_URL}/cjenici-postaja/${postajaId}`
      )
      .then(function (response) {
        const tipoviGoriva = [
          "Benzinska goriva",
          "Dizelska goriva",
          "Autoplin",
          "Plinsko ulje"
        ];
        const cjenici = response.data.filter(cjenik => {
          const gorivo = gorivos_obj[cjenik.gorivo_id];
          if (!gorivo) {
            return false;
          }
          return tipoviGoriva.includes(
            gorivo.vrsta_goriva.tip_goriva.tip_goriva
          );
        });

        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        var gorivos = cjenici.map(x => x.gorivo_id).filter(onlyUnique);
        const gorivo2cijene = {};
        gorivos.forEach(gorivo => {
          gorivo2cijene[gorivo] = { arr: [], count: 0 };
        });
        const dates = [];
        let lastDate = null;
        cjenici.forEach(cjenik => {
          const date = cjenik.dat_poc;
          const gorivo = cjenik.gorivo_id;
          const cijena = cjenik.cijena;
          if (!lastDate || date !== lastDate) {
            gorivos.forEach(gorivo => {
              const arr = gorivo2cijene[gorivo].arr;
              if (arr.length === 0) {
                arr.push(null);
              } else {
                arr.push(arr[arr.length - 1]);
                gorivo2cijene[gorivo].count = 0;
              }
            });
            gorivo2cijene[gorivo].arr.pop();
            gorivo2cijene[gorivo].arr.push(cijena);
            gorivo2cijene[gorivo].count = 1;
            dates.push(date);
            lastDate = date;
          } else {
            const count = gorivo2cijene[gorivo].count;
            const avgCijena = gorivo2cijene[gorivo].arr.pop();
            const newAvgCijena = (avgCijena * count + cijena) / (count + 1);
            gorivo2cijene[gorivo].arr.push(newAvgCijena);
            gorivo2cijene[gorivo].count = gorivo2cijene[gorivo].count + 1;
          }
        });
        function formatDateStr(dateStr) {
          const [y, m, d] = dateStr.split("-");
          return `${d}.${m}.${y}.`;
        }
        const series = [];
        const legendNames = [];
        for (let [key, value] of Object.entries(gorivo2cijene)) {
          series.push(
            value.arr.map((x, i) => ({
              meta: formatDateStr(dates[i]),
              value: x
            }))
          );
          legendNames.push(gorivos_obj[key].naziv);
        }
        setLabels(dates.map(x => formatDateStr(x)));
        setSeries(series);
        setLegendNames(legendNames);
        setLoading(false);
      })
      .catch(function (error) {
        setError(true);
        console.log(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <ErrorComponent title="Greška prilikom dohvaćanja podataka za Grafikon cijena." />
    );
  }

  return <Grafikon labels={labels} series={series} legendNames={legendNames} />;
}

export default PostajaGrafikon;
