import {
  createRadiusPredicate,
  createTipGorivaPredicate,
  createVrstaGorivaArrayPredicate,
  createOpcijaArrayPredicate,
  createDanUTjednuArrayPredicate,
  createRadnoVrijemeArrayPredicate,
  createObveznikArrayPredicate
} from "./postajaPredicates";

export function postajasFilter(postajas, filterArgs) {
  return postajas
    .filter(createRadiusPredicate(filterArgs.radius))
    .filter(createTipGorivaPredicate(filterArgs.tipGoriva))
    .filter(createVrstaGorivaArrayPredicate(filterArgs.vrstaGorivaArray))
    .filter(createOpcijaArrayPredicate(filterArgs.opcijaArray))
    .filter(createDanUTjednuArrayPredicate(filterArgs.danUTjednuArray))
    .filter(createRadnoVrijemeArrayPredicate(filterArgs.radnoVrijemeArray))
    .filter(createObveznikArrayPredicate(filterArgs.obveznikArray));
}
