export function createRadiusPredicate(radius) {
  return postaja => {
    return postaja.udaljenost <= radius;
  };
}

export function createTipGorivaPredicate(tipGoriva) {
  return postaja => {
    if (tipGoriva === "") {
      return true;
    }

    const tipGorivaIdsUPostaji = postaja.cjeniks.map(
      cjenik => cjenik.gorivo.vrsta_goriva.tip_goriva_id
    );

    return tipGorivaIdsUPostaji.includes(+tipGoriva);
  };
}

export function createVrstaGorivaArrayPredicate(vrstaGorivaArray) {
  return postaja => {
    if (vrstaGorivaArray.length === 0) {
      return true;
    }

    const vrstaGorivaIdsUPostaji = Object.values(postaja.cjeniks).map(
      cjenik => cjenik.gorivo.vrsta_goriva
    );

    return vrstaGorivaIdsUPostaji.some(vrstaGoriva =>
      vrstaGorivaArray.includes(vrstaGoriva.id)
    );
  };
}

export function createOpcijaArrayPredicate(opcijaArray) {
  return postaja => {
    if (opcijaArray.length === 0) {
      return true;
    }

    const opcijaIds = postaja.opcijas.map(opcija => opcija.opcija_id);
    return opcijaArray.every(opcija => opcijaIds.includes(opcija));
  };
}

export function createDanUTjednuArrayPredicate(danUTjednuArray) {
  return postaja => {
    if (danUTjednuArray.length === 0) {
      return true;
    }

    const nazivi = danUTjednuArray.map(dan => {
      switch (dan) {
        case 0:
          return "nedjelja";
        case 6:
          return "subota";
        default:
          return "radni";
      }
    });

    return postaja.radno_vrijemes
      .filter(rv => nazivi.includes(rv.vrsta_dana.vrsta))
      .every(rv => rv.pocetak !== null);
  };
}

export function createRadnoVrijemeArrayPredicate(radnoVrijemeArray) {
  return postaja => {
    if (radnoVrijemeArray.length === 0) {
      return true;
    }

    return radnoVrijemeArray.every(radnoVrijeme => {
      const [pocetak, kraj] = radnoVrijeme.split("-");
      return postaja.radno_vrijemes.some(
        rv => rv.pocetak <= pocetak && rv.kraj >= kraj
      );
    });
  };
}

export function createObveznikArrayPredicate(obveznikArray) {
  return postaja => {
    if (obveznikArray.length === 0) {
      return true;
    }

    return obveznikArray.includes(postaja.obveznik_id);
  };
}
