// export function createAddCjeniksForTipGorivaConsumer(tipGoriva) {
//   return postaja => {
//     const filteredCjeniks = cjeniksFilter(postaja.cjeniks, filterArgs);

//     filteredCjeniks.sort(function(a, b) {
//       return a.cijena - b.cijena;
//     });

//     postaja.cjeniksTipaGoriva = filteredCjeniks;
//   };
// }

export function createAddOpenConsumer(currentDay, currentTime) {
  return postaja => {
    postaja.open = 0;

    const flag = postaja.obavijests.some(obavijest => !obavijest.radi);
    if (flag) {
      return;
    }

    const filteredRadnoVrijemes = postaja.radno_vrijemes.filter(
      radno_vrijeme => radno_vrijeme.vrsta_dana.vrsta === currentDay
    );

    if (filteredRadnoVrijemes.length === 0) {
      return;
    }

    filteredRadnoVrijemes.forEach(radnoVrijeme => {
      if (
        radnoVrijeme.pocetak <= currentTime &&
        radnoVrijeme.kraj >= currentTime
      ) {
        postaja.open = 1;
      }
    });
    postaja.radnoVrijemesDanas = filteredRadnoVrijemes;
  };
}
