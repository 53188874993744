import React, { useContext, useState } from "react";

import { DataContext } from "../App";
import OutsideAlerter from "./OutsideAlerter";

function CijeneEmailomObrazac(props) {
  const { tip_gorivas } = useContext(DataContext);

  const [formData, setFormData] = useState({
    MERGE0: "",
    MERGE1: "",
    MERGE2: "",
    MERGE3: "",
    MERGE5: "",
  });

  const handleChange = event => {
    const target = event.target;
    setFormData({ ...formData, [target.name]: target.value });
  };

  const [isWarnOpen, setIsWarnOpen] = useState(false);
  let warningDiv;
  if (isWarnOpen) {
    warningDiv = (
      <OutsideAlerter
        callback={() => {
          const el = document.getElementsByTagName("details")[0];
          el.removeAttribute("open");
          setIsWarnOpen(false);
        }}
      >
        <div className="warning-content">
          <p>
            Postaje možete pretraživati i prema njihovim dodatnim uslugama.
            Kliknite Detaljno filtriranje ispod mape i pronađite postaje s
            dodatnim karakteristikama, ponudama i uslugama
          </p>
          <p>
            Ukoliko želite primati obavijesti o cijenama goriva na email,
            prijavite se pomoću forme na dnu naslovne stranice
          </p>
          <p>
            Stranicu je preporučljivo pregledavati pomoću preglednika Chrome,
            Safari, Firefox ili novije verzije Internet Explorer (10 i 11)
          </p>
          <p>
            Na stranici svake postaje možete vidjeti trend kretanja cijena
            osnovnih vrsta goriva
          </p>
        </div>
      </OutsideAlerter>
    );
  }

  return (
    <article className="cijene-emailom-obrazac">
      {/* <link
        href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
        rel="stylesheet"
        type="text/css"
      /> */}
      <div id="mc_embed_signup">
        <form
          action="https://min-go.us10.list-manage.com/subscribe/post?u=085843958dfcdf0a3364bc802&amp;id=56d8c76cfc"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          // className="validate"
          className="cijene-mailom"
          target="_blank"
          noValidate
        >
          <h2>Primajte cijene e-mailom</h2>
          <p>
            Želite primati obavijesti putem e-maila o promijeni cijena goriva?
            Ništa lakše! Popunite ova polja svojim podacima i uskoro ćete početi
            primati cijene goriva.
          </p>
          {/* <div className="indicates-required">
            <span className="asterisk">*</span> označava obavezno
          </div> */}
          <div id="mc_embed_signup_scroll" className="form-items">
            <div className="form-item">
              <input
                type="email"
                id="mce-EMAIL"
                name="MERGE0"
                placeholder="E-adresa"
                // pattern=".+@globex.com"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                size="30"
                required
                value={formData.MERGE0}
                onChange={handleChange}
              />
              <label htmlFor="mce-EMAIL">
                {/* E-adresa <span className="asterisk">*</span> */}
                E-adresa
              </label>
            </div>
            <div className="form-item">
              <input
                type="text"
                value={formData.MERGE1}
                onChange={handleChange}
                name="MERGE1"
                id="mce-MERGE1"
                placeholder="Ime"
                size="30"
              />
              <label htmlFor="mce-MERGE1">Ime</label>
            </div>
            <div className="form-item">
              <input
                type="text"
                value={formData.MERGE2}
                onChange={handleChange}
                name="MERGE2"
                id="mce-MERGE2"
                placeholder="Prezime"
                size="30"
              />
              <label htmlFor="mce-MERGE2">Prezime</label>
            </div>
            <div className="form-item">
              <select
                name="MERGE3"
                id="mce-MERGE3"
                required
                value={formData.MERGE3}
                onChange={handleChange}
              >
                <option value="" disabled>
                  –Vrsta goriva–
                </option>
                <option value="Benzinska goriva">Benzinska goriva</option>
                <option value="Dizel goriva">Dizel goriva</option>
                <option value="Autoplin">Autoplin</option>
                {/* <option value="Plavi dizel">Plavi dizel</option> */}
                <option value="Plinsko ulje">Plinsko ulje</option>
              </select>
              <label htmlFor="mce-MERGE3">Vrsta goriva</label>
            </div>
            <div className="form-item">
              <select
                name="MERGE5"
                id="mce-MERGE5"
                required
                value={formData.MERGE5}
                onChange={handleChange}
              >
                <option value="" disabled>
                  –Županija–
                </option>
                <option value="ZAGREBAČKA">ZAGREBAČKA</option>
                <option value="KRAPINSKO-ZAGORSKA">KRAPINSKO-ZAGORSKA</option>
                <option value="SISAČKO-MOSLAVAČKA">SISAČKO-MOSLAVAČKA</option>
                <option value="KARLOVAČKA">KARLOVAČKA</option>
                <option value="VARAŽDINSKA">VARAŽDINSKA</option>
                <option value="KOPRIVNIČKO-KRIŽEVAČKA">KOPRIVNIČKO-KRIŽEVAČKA</option>
                <option value="BJELOVARSKO-BILOGORSKA">BJELOVARSKO-BILOGORSKA</option>
                <option value="PRIMORSKO-GORANSKA">PRIMORSKO-GORANSKA</option>
                <option value="LIČKO-SENJSKA">LIČKO-SENJSKA</option>
                <option value="VIROVITIČKO-PODRAVSKA">VIROVITIČKO-PODRAVSKA</option>
                <option value="POŽEŠKO-SLAVONSKA">POŽEŠKO-SLAVONSKA</option>
                <option value="BRODSKO-POSAVSKA">BRODSKO-POSAVSKA</option>
                <option value="ZADARSKA">ZADARSKA</option>
                <option value="ŠIBENSKO-KNINSKA">ŠIBENSKO-KNINSKA</option>
                <option value="VUKOVARSKO-SRIJEMSKA">VUKOVARSKO-SRIJEMSKA</option>
                <option value="SPLITSKO-DALMATINSKA">SPLITSKO-DALMATINSKA</option>
                <option value="ISTARSKA">ISTARSKA</option>
                <option value="DUBROVAČKO-NERETVANSKA">DUBROVAČKO-NERETVANSKA</option>
                <option value="MEĐIMURSKA">MEĐIMURSKA</option>
                <option value="GRAD ZAGREB">GRAD ZAGREB</option>
              </select>
              <label htmlFor="mce-MERGE5">Županija</label>
            </div>
            {/* <div className="form-item">
              <select name="MERGE5" className="" id="mce-MERGE5">
                <option value="default" disabled>
                  –Vrsta goriva–
                </option>
                {tip_gorivas.map(tipGoriva => (
                  <option key={tipGoriva.id} value={tipGoriva.id}>
                    {tipGoriva.tip_goriva}
                  </option>
                ))}
              </select>
              <label htmlFor="mce-MERGE5">Dodatna vrsta goriva</label>
            </div> */}
            <div id="mce-responses" className={{ clear: "both" }}>
              <div
                // className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                // className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="hidden"
                name="b_085843958dfcdf0a3364bc802_56d8c76cfc"
                tabIndex="-1"
                value=""
              />
            </div>
          </div>
          <div className="form-submit">
            <input
              type="submit"
              value="Šalji mi cijene goriva"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </div>
        </form>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
      <script type="text/javascript">
        {
          "(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='MERGE3';ftypes[3]='dropdown';fnames[4]='MERGE5';ftypes[4]='dropdown'; }(jQuery));var $mcj = jQuery.noConflict(true);"
        }
      </script>
      <details className="warning">
        <summary onClick={() => setIsWarnOpen(true)}>
          Upute za korištenje
        </summary>
        {warningDiv}
      </details>
    </article>
  );
}

export default CijeneEmailomObrazac;
