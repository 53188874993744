import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import notifier from "simple-react-notifications";

import { LoadingContext } from "../App";

function Kontakt(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initFormData = {
    email: "",
    ime: "",
    prezime: "",
    poruka: ""
  };
  const [formData, setFormData] = useState(initFormData);
  const { setLoading } = useContext(LoadingContext);
  const handleChange = event => {
    const target = event.target;
    setFormData({ ...formData, [target.name]: target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/kontakt`, formData)
      .then(function(response) {
        setFormData(initFormData);
        setLoading(false);
        notifier.success("Vaš upit je poslan.");
      })
      .catch(function(error) {
        console.log(error);
        notifier.configure({
          autoClose: false,
          closeOnClick: true
        });
        setLoading(false);
        notifier.error(
          `Dogodila se greška prilikom slanja Vašeg upita. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${process.env.REACT_APP_EMAIL}.`,
          { autoClose: false }
        );
      });
    setLoading(true);
  };

  return (
    <main id="main-content">
      <h2 className="text-center">Kontakt</h2>
      <p className="text-center">Pošaljite nam upit!</p>
      <form className="cijene-mailom kontakt" onSubmit={handleSubmit}>
        <div className="form-items">
          <div className="form-item kf">
            <input
              type="email"
              id="email2"
              name="email"
              placeholder="E-adresa"
              // pattern=".+@globex.com"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              size="30"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <label htmlFor="email">E-adresa</label>
          </div>
        </div>
        <div className="form-items">
          <div className="form-item kf">
            <input
              type="text"
              id="ime2"
              name="ime"
              placeholder="Ime"
              size="30"
              required
              value={formData.ime}
              onChange={handleChange}
            />
            <label htmlFor="ime">Ime</label>
          </div>
        </div>
        <div className="form-items">
          <div className="form-item kf">
            <input
              type="text"
              id="prezime2"
              name="prezime"
              placeholder="Prezime"
              size="30"
              required
              value={formData.prezime}
              onChange={handleChange}
            />
            <label htmlFor="prezime">Prezime</label>
          </div>
        </div>
        <div className="form-items">
          <div className="form-item kf zadnji">
            <textarea
              placeholder="Vaša poruka"
              id="poruka"
              name="poruka"
              required
              value={formData.poruka}
              onChange={handleChange}
            ></textarea>
            <label htmlFor="poruka">Poruka</label>
          </div>
        </div>

        <div className="form-submit">
          <input type="submit" value="Pošalji" className="postaja-odvedi" />
        </div>
      </form>
    </main>
  );
}

export default Kontakt;
