import React, { useEffect } from "react";
import TrazilicaGoriva from "./TrazilicaGoriva";
import NaslovnicaGrafikon from "./NaslovnicaGrafikon";

function Naslovnica(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main-content">
      <TrazilicaGoriva coords={props.coords} />
      <NaslovnicaGrafikon />
    </main>
  );
}

export default Naslovnica;
