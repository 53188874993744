import React, { useState, useEffect } from "react";
import axios from "axios";
import Postaja from "./Postaja";
import ErrorComponent from "./ErrorComponent";

function TrazilicaRezultati(props) {
  const { postajas, coords } = props;
  const openPostajas = [];
  postajas.some(postaja => {
    if (postaja.open) {
      openPostajas.push(postaja);
    }
    return !postaja.open;
  });

  const [brojPrikazanihPostaja, setBrojPrikazanihPostaja] = useState(
    Math.min(5, openPostajas.length)
  );

  const handleClick = e => {
    const noviBrojPrikazanihPostaja = Math.min(
      brojPrikazanihPostaja + 10,
      openPostajas.length
    );

    setBrojPrikazanihPostaja(noviBrojPrikazanihPostaja);
  };

  if (openPostajas.length === 0) {
    return (
      <section className="trazilica-rezultati">
        <h2>Otvorene postaje</h2>
        <ErrorComponent
          subject={
            "Na ovom području nema postaja. Pogledajte imate li upaljene filtere. Pokušajte povećati doseg ili potražiti drugu lokaciju."
          }
          subjectStyle={{ fontSize: "14px", marginBottom: "40px" }}
          titleStyle={{ marginTop: "20px" }}
        />
      </section>
    );
  }

  return (
    <section className="trazilica-rezultati">
      <h2>Otvorene postaje</h2>
      {openPostajas.slice(0, 5).map(postaja => (
        <Postaja
          key={postaja.id}
          postaja={postaja}
          coords={coords}
          showDetails={true}
        />
      ))}
      <hr />
      {openPostajas.slice(5, brojPrikazanihPostaja).map(postaja => (
        <Postaja
          key={postaja.id}
          postaja={postaja}
          coords={coords}
          showDetails={true}
        />
      ))}
      {brojPrikazanihPostaja === openPostajas.length ? null : (
        <button className="postaje-sve" onClick={handleClick}>
          Prikaži više
        </button>
      )}
    </section>
  );
}

export default TrazilicaRezultati;
