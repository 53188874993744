import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { geolocated } from "react-geolocated";
import axios from "axios";
import http from "http";
import zlib from "zlib";
import L from "leaflet";
import notifier from "simple-react-notifications";

import Spinner from "./components/Spinner";
import NavAccess from "./components/NavAccess";
import Header from "./components/Header";
import PostajaStranica from "./components/PostajaStranica";
import Obveznici from "./components/Obveznici";
import Kontakt from "./components/Kontakt";
import Info from "./components/Info";
import Projekt from "./components/Projekt";
import Prijava from "./components/Prijava";
import Registracija from "./components/Registracija";
import Naslovnica from "./components/Naslovnica";
import Footer from "./components/Footer";
import ResetiranjeZaporke from "./components/ResetiranjeZaporke";
import PrijavaGreske2 from "./components/PrijavaGreske2";
import Odjava from "./components/Odjava";
import ErrorComponent from "./components/ErrorComponent";
import Privatnost from "./components/Privatnost";

export const DataContext = React.createContext();
export const CoordsContext = React.createContext({
  coords: null,
  setCoords: () => { }
});
export const MapContext = React.createContext();
export const AuthContext = React.createContext({
  auth: null,
  login: () => { },
  signOut: () => { }
});
export const LoadingContext = React.createContext({
  loading: null,
  setLoading: () => { }
});

function App(props) {
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    // configures notifications
    notifier.configure({
      autoClose: true,
      position: "top-center",
      autoClose: 2 * 3000,
      delay: 0,
      closeOnClick: true
    });
  }, []);

  const [coords, setCoords] = useState({
    latitude: 45.813177,
    longitude: 15.977048
  });
  useEffect(() => {
    if (
      props.isGeolocationAvailable &&
      props.isGeolocationEnabled &&
      props.coords
    ) {
      setCoords({
        latitude: props.coords.latitude,
        longitude: props.coords.longitude
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coords]);

  const [currentPath, setCurrentPath] = useState(props.location.pathname);
  useEffect(() => {
    const { pathname } = props.location;
    setCurrentPath(pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  const [map, setMap] = useState();
  useEffect(() => {
    setMap(L.map("map").setView([51.505, -0.09], 13));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [auth, setAuth] = useState(null);
  const login = (username, token) => {
    localStorage.setItem("username", username);
    localStorage.setItem("token", token);
    setAuth({ username: username, token: token });
    props.history.push("/");
  };
  const signOut = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    setAuth(null);
    props.history.push("/");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_API_URL}/check`, {
          headers: { Authorization: `JWT ${token}` }
        })
        .then(function (response) {
          login(localStorage.getItem("username"), token);
        })
        .catch(function (error) {
          signOut();
        });
    }
  }, []);

  useEffect(() => {
    function getGzipped(url, callback) {
      // buffer to store the streamed decompression
      var buffer = [];

      http
        .get(url, function (res) {
          // pipe the response into the gunzip to decompress
          var gunzip = zlib.createGunzip();
          res.pipe(gunzip);

          gunzip
            .on("data", function (data) {
              // decompression chunk ready, add it to the buffer
              buffer.push(data.toString());
            })
            .on("end", function () {
              // response and decompression complete, join the buffer and return
              callback(null, buffer.join(""));
            })
            .on("error", function (e) {
              callback(e);
            });
        })
        .on("error", function (e) {
          callback(e);
          setError(true);
          setLoading2(false);
        });
    }

    getGzipped(process.env.REACT_APP_DATA_URL, function (err, decompressed) {
      const data = processResponseData(JSON.parse(decompressed));

      setData(data);
      setLoading2(false);
    });

    // axios
    //   .get(`${process.env.REACT_APP_BACKEND_API_URL}/data`)
    //   .then(response => {
    //     const data = processResponseData(response.data);

    //     setData(data);
    //     setLoading2(false);
    //   })
    //   .catch(function(error) {
    //     setError(true);
    //     setLoading2(false);
    //   });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const qs = require("query-string");
    const urlParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    });
    if (props.location.pathname !== "/" || !urlParams.token) {
      return;
    }
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API_URL}/confirm/${urlParams.token}`
      )
      .then(function (response) {
        if (response.data.ok) {
          notifier.success("Uspješno se potvrdili registraciju.", {
            position: "top-center",
            autoClose: 2 * 7000
          });
        } else {
          notifier.info(response.data.msg, {
            position: "top-right",
            autoClose: 3000
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        notifier.error(
          `Dogodila se greška potvrde registracije. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${process.env.REACT_APP_EMAIL}.`
        );
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading2) {
    return <Spinner />;
  }

  if (error) {
    return (
      <ErrorComponent title="Greška prilikom dohvaćanja podataka potrebnih za rad aplikacije." />
    );
  }

  return (
    <DataContext.Provider value={data}>
      <AuthContext.Provider value={{ auth, login, signOut }}>
        <CoordsContext.Provider value={{ coords, setCoords }}>
          <MapContext.Provider value={map}>
            <LoadingContext.Provider value={{ loading, setLoading }}>
              <LoadingOverlay active={loading} text="">
                <noscript>
                  You need to enable JavaScript to run this app.
                </noscript>
                <NavAccess />
                <Header
                  currentPath={currentPath}
                  userLogged={auth ? true : false}
                  username={auth ? auth.username : null}
                />
                <Switch>
                  {data.postajas.map(postaja => (
                    <Route key={postaja.id} path={postaja.url}>
                      <PostajaStranica id={postaja.id} postaja={postaja} />
                    </Route>
                  ))}
                  {/* {data.postajas.map(postaja => (
                    <Route key={postaja.id} path={`/${postaja.id}`}>
                      <PostajaStranica id={postaja.id} postaja={postaja} />
                    </Route>
                  ))} */}
                  <Route path="/obveznici">
                    <Obveznici data={data} />
                  </Route>
                  <Route path="/kontakt">
                    <Kontakt />
                  </Route>
                  <Route path="/info">
                    <Info />
                  </Route>
                  <Route path="/projekt">
                    <Projekt />
                  </Route>
                  {!auth && (
                    <Route path="/prijava">
                      <Prijava />
                    </Route>
                  )}
                  {!auth && (
                    <Route path="/registracija">
                      <Registracija />
                    </Route>
                  )}
                  {auth && (
                    <Route path="/odjava">
                      <Odjava />
                    </Route>
                  )}
                  <Route exact path="/">
                    <Naslovnica coords={coords} />
                  </Route>
                  <Route path="/resetiranje-lozinke">
                    <ResetiranjeZaporke />
                  </Route>
                  {auth && (
                    <Route path="/prijava-greske">
                      <PrijavaGreske2 />
                    </Route>
                  )}
                  <Route path="/privatnost">
                    <Privatnost />
                  </Route>
                </Switch>
                <Footer />
              </LoadingOverlay>
            </LoadingContext.Provider>
          </MapContext.Provider>
        </CoordsContext.Provider>
      </AuthContext.Provider>
    </DataContext.Provider>
  );

  function processResponseData(responseData) {
    const data = {};
    const arrayToObject = (array, property) => {
      const keyValueObj = {};
      array.forEach(item => (keyValueObj[item[property]] = item));
      return keyValueObj;
    };
    Object.entries(responseData).forEach(([k, v]) => {
      data[k] = v;
      data[k + "_obj"] = arrayToObject(v, "id");
    });

    // data.obvezniks.sort((a, b) => (a.naziv.toLowerCase().localeCompare(b.naziv.toLowerCase())));

    const tipoviGoriva = ["Benzinska goriva", "Dizelska goriva", "Autoplin", "Plinsko ulje"];
    data.tip_gorivas = data.tip_gorivas.filter(tip_goriva =>
      tipoviGoriva.includes(tip_goriva.tip_goriva)
    );
    data.opcina_grads.forEach(v => {
      v.zupanija = data.zupanijas_obj[v.zupanija_id];
    });
    data.naseljes = data.naseljes.filter(v => v.lat && v.lng);
    data.naseljes.forEach(v => {
      v.opcina_grad = data.opcina_grads_obj[v.opcina_grad_id];
    });
    data.suggestions = [];
    data.naseljes.forEach(v => {
      const suggestion = {
        id: v.id,
        name:
          v.ime +
          ", " +
          v.opcina_grad.ime_jedinice +
          ", " +
          v.opcina_grad.zupanija.naziv,
        lat: v.lat,
        lng: v.lng
      };
      data.suggestions.push(suggestion);
    });
    data.suggestions_obj = arrayToObject(data.suggestions, "id");
    data.vrsta_gorivas.forEach(v => {
      v.tip_goriva = data.tip_gorivas_obj[v.tip_goriva_id];
    });
    const tipGorivaIds = data.tip_gorivas.map(tipGoriva => tipGoriva.id);
    data.vrsta_gorivas = data.vrsta_gorivas.filter(
      v => v.tip_goriva !== undefined && tipGorivaIds.includes(v.tip_goriva.id)
    );
    data.gorivos.forEach(v => {
      v.vrsta_goriva = data.vrsta_gorivas_obj[v.vrsta_goriva_id];
    });
    const vrstaGorivaIds = data.vrsta_gorivas.map(
      vrstaGoriva => vrstaGoriva.id
    );
    data.gorivos = data.gorivos.filter(
      v =>
        v.vrsta_goriva !== undefined &&
        vrstaGorivaIds.includes(v.vrsta_goriva.id)
    );
    const gorivoIds = data.gorivos.map(gorivo => gorivo.id);
    data.postajas = data.postajas.filter(v => {
      return !(
        !v.lat ||
        isNaN(+v.lat) ||
        parseFloat(v.lat.replace(',', '.')) < -180 ||
        parseFloat(v.lat.replace(',', '.')) > 180 ||
        !v.long ||
        isNaN(+v.long) ||
        parseFloat(v.long.replace(',', '.')) < -90 ||
        parseFloat(v.long.replace(',', '.')) > 90
      );
    });
    data.postajas.forEach(postaja => {
      postaja.lat = parseFloat(postaja.lat.replace(',', '.'));
      postaja.long = parseFloat(postaja.long.replace(',', '.'));

      postaja.obveznik = data.obvezniks_obj[postaja.obveznik_id];
      postaja.opcijas = postaja.opcije;
      postaja.opcijas_obj = arrayToObject(postaja.opcije, "id");
      postaja.opcijas.forEach(v => {
        v.opcija = data.opcijas_obj[v.opcija_id];
      });
      postaja.opcijas = postaja.opcijas.filter(v => v.opcija !== undefined);
      postaja.cjeniks = postaja.cjenici;
      postaja.cjeniks_obj = arrayToObject(postaja.cjenici, "id");
      postaja.cjeniks.forEach(v => {
        v.gorivo = data.gorivos_obj[v.gorivo_id];
      });
      postaja.cjeniks = postaja.cjeniks.filter(
        v => v.gorivo !== undefined && gorivoIds.includes(v.gorivo.id)
      );
      postaja.radno_vrijemes = postaja.radnaVremena;
      postaja.radno_vrijemes_obj = arrayToObject(postaja.radnaVremena, "id");
      postaja.radno_vrijemes.forEach(v => {
        v.vrsta_dana = data.vrsta_danas_obj[v.vrsta_dana_id];
      });
      postaja.radno_vrijemes = postaja.radno_vrijemes.filter(
        v => v.vrsta_dana !== undefined
      );
      postaja.obavijests = postaja.obavijesti;
      postaja.obavijests_obj = arrayToObject(postaja.obavijesti, "id");
      postaja.url = `/${postaja.url}`;
      // postaja.url = `/${postaja.id}-${postaja.obveznik.naziv.replace(
      //   /\s+/g,
      //   "-"
      // )}-${postaja.naziv.replace(/\s+/g, "-")}`;
      // postaja.url = `/${postaja.id}`;
    });
    data.postajas = data.postajas.filter(v => {
      return !(v.cjeniks.length === 0 || v.radno_vrijemes.length === 0);
    });
    return data;
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true
  },
  userDecisionTimeout: 30000
})(withRouter(App));
