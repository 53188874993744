import React, { useEffect, useContext, useState } from "react";
import notifier from "simple-react-notifications";
import "simple-react-notifications/dist/index.css";
import Select from "react-select";
import { getDistance } from "geolib";
import SlideToggle from "react-slide-toggle";

import TrazilicaRezultatiOtvorene from "./TrazilicaRezultatiOtvorene";
import TrazilicaRezultatiOtvorene2 from "../widgets/TrazilicaRezultatiOtvorene2";
import { TabController } from "./tmp/TabController";

import { DataContext, CoordsContext } from "../App";

function TrazilicaObrazac(props) {
  const {
    filterArgs,
    setFilterArgs,
    onChange,
    postajas,
    onMouseEnter,
    onMouseLeave
  } = props;

  const {
    tip_gorivas,
    vrsta_gorivas,
    obvezniks,
    suggestions,
    suggestions_obj
  } = useContext(DataContext);

  const { coords, setCoords } = useContext(CoordsContext);

  const handleTrazilicaOtvoreneJump = e => {
    // if (window.location.hash === "#trazilica-otvorene-jump") {
    //   window.location = "#";
    // } else {
    //   window.location = "#trazilica-otvorene-jump";
    // }
  };

  const handleGunpointClick = e => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setPosition, error);
    } else {
      /* geolocation IS NOT available */
    }
  };

  function setPosition(position) {
    setCoords({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude + (1 + Math.random()) * 1e-6
    });
  }

  const attributeNames = [
    "vrstaGoriva",
    "opcija",
    "danUTjednu",
    "radnoVrijeme",
    "obveznik"
  ];

  const handleSubmitFilter = e => {
    e.preventDefault();

    const newFilterArgs = { ...filterArgs };
    attributeNames.forEach(attributeName => {
      const values = Array.from(
        document.querySelectorAll("[name=" + attributeName + "]")
      )
        .filter(e => e.checked)
        .map(e => (isNaN(+e.value) ? e.value : +e.value));
      const propertyName = attributeName + "Array";
      newFilterArgs[propertyName] = [...values];
    });
    setFilterArgs(newFilterArgs);

    // window.location = "#";
    // window.location = "#trazilica-otvorene-jump";
    window.location = "#main-nav-open";
  };

  function error(postitionError) {
    if (postitionError.code === 1)
      notifier.error(
        "Dozvola za geolokaciju blokirana je jer je korisnik nekoliko puta odbacio upit za dozvolu. To se može resetirati u informacijama o stranici kojima se može pristupiti klikom na ikonu zaključavanja pored URL-a."
      );
    else {
      notifier.error(postitionError.message);
    }
  }

  useEffect(() => {
    new TabController("#trazilica-filtri");
  }, []);

  const [selectedValue, setSelectedValue] = useState();
  useEffect(() => {
    const closestSuggestion = findClosestSuggestion(coords);
    setSelectedValue({
      value: closestSuggestion.id,
      label: closestSuggestion.name
    });
    // setSelectedOption({
    //   value: closestSuggestion.id,
    //   label: closestSuggestion.name
    // });

    // setInputValue(closestSuggestion.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  const findClosestSuggestion = positionCoords => {
    const selectedSuggestion = suggestions.find(suggestion => suggestion.selected);
    if (selectedSuggestion) {
      selectedSuggestion.selected = false;
      return selectedSuggestion;
    }

    let closestSuggestion;
    let minDistance;
    suggestions.forEach(suggestion => {
      const distance = getDistance(positionCoords, {
        latitude: suggestion.lat,
        longitude: suggestion.lng
      });
      if (!closestSuggestion || distance < minDistance) {
        closestSuggestion = suggestion;
        minDistance = distance;
      }
    });
    return closestSuggestion;
  };

  const handleChange = selectedOption => {
    // setSelectedOption(selectedOption);

    if (!selectedOption) {
      setSelectedValue(undefined);
      return;
    }

    const suggestion = suggestions_obj[selectedOption.value];
    suggestion.selected = true;

    setCoords({
      latitude: parseFloat(suggestion.lat),
      longitude: parseFloat(suggestion.lng) + (1 + Math.random()) * 1e-6
    });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: "Arial"
    }),
    dropdownIndicator: () => ({
      display: "none"
    }),
    // singleValue: (provided, state) => {
    //   // const opacity = state.isDisabled ? 0.5 : 1;
    //   // const transition = "opacity 300ms";
    //   //console.log(state);
    //   return {
    //     ...provided,
    //     fontFamily: "Arial",
    //     padding: "1.6rem",
    //     display: getSelectProps().menuIsOpen ? "none" : "block"
    //   };
    // },
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999
    })
  };

  const [selectProps, setSelectProps] = useState();
  function getSelectProps() {
    return this.selectProps;
  }

  // useEffect(() => {
  //   console.log("defaultValue", defaultValue);

  //   // if (!defaultValue) {
  //   //   return;
  //   // }

  //   setSelectProps(
  //     <Select
  //       placeholder={"Upišite lokaciju u tražilicu"}
  //       isClearable={true}
  //       value={defaultValue}
  //       // value={selectedOption}
  //       onChange={handleChange}
  //       styles={customStyles}
  //       options={suggestions.map(suggestion => ({
  //         value: suggestion.id,
  //         label: suggestion.name
  //       }))}
  //       isMulti={false}
  //       filterOption={(option, input) => {
  //         input = input.trim();
  //         if (input.length < 2) {
  //           return false;
  //         }

  //         return option.label.toLowerCase().startsWith(input.toLowerCase());
  //       }}
  //     />
  //   );
  // }, [defaultValue]);

  const [toggleEvent, setToggleEvent] = useState(0);
  const onToggle = () => {
    setToggleEvent(Date.now());
  };

  // const [inputValue, setInputValue] = useState("");

  return (
    <section className="trazilica-obrazac" id="trazilica">
      <h1>Tražilica najjeftinijeg goriva</h1>

      <div>
        {/* <button className="toggle" onClick={onToggle}>
          Toggle
        </button> */}
        <SlideToggle toggleEvent={toggleEvent}>
          {({ setCollapsibleElement }) => (
            <div className="my-collapsible">
              <div
                className="my-collapsible__content"
                ref={setCollapsibleElement}
              >
                <div className="my-collapsible__content-inner">
                  <form
                    className="trazilica"
                    id="trazilica-toggle"
                    onSubmit={e => e.preventDefault()}
                  >
                    <div className="trazi-lokacija">
                      {/* <Autocomplete
            suggestions={suggestions}
            value={lokacija}
            setValue={setLokacija}
            onSubmit={handleTrazilica}
          /> */}
                      {/* {selectProps} */}
                      <Select
                        // inputValue={inputValue}
                        // onInputChange={value => {
                        //   setInputValue(value);
                        // }}
                        placeholder={"Upišite lokaciju u tražilicu"}
                        isClearable={true}
                        value={selectedValue}
                        onChange={handleChange}
                        styles={customStyles}
                        options={suggestions.map(suggestion => ({
                          value: suggestion.id,
                          label: suggestion.name
                        }))}
                        isMulti={false}
                        filterOption={(option, input) => {
                          input = input.trim();
                          if (input.length < 2) {
                            return false;
                          }

                          return option.label
                            .toLowerCase()
                            .startsWith(input.toLowerCase());
                        }}
                      />
                      <button
                        type="submit"
                        value="Traži"
                        onClick={handleTrazilicaOtvoreneJump}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29.414"
                          height="29.414"
                          viewBox="0 0 29.414 29.414"
                          role="img"
                          aria-labelledby="trazi-title"
                          focusable="false"
                        >
                          <title id="trazi-title">Traži</title>
                          <defs>
                            <style>
                              .tra{"{opacity:0.26;}"}.trb
                              {
                                "{fill:none;stroke:#2c313c;stroke-linecap:square;stroke-miterlimit:10;stroke-width:2px;}"
                              }
                            </style>
                          </defs>
                          <g className="tra" transform="translate(-1 -1)">
                            <line
                              className="trb"
                              x1="8.223"
                              y1="8.223"
                              transform="translate(20.777 20.777)"
                            />
                            <circle
                              className="trb"
                              cx="11"
                              cy="11"
                              r="11"
                              transform="translate(2 2)"
                            />
                          </g>
                        </svg>
                      </button>
                      {/* <button
                        id="otvori-otvorene-postaje"
                        className="otvori-otvorene-postaje"
                        aria-label="Izlistaj otvorene postaje"
                        onClick={handleGunpointClick}
                      >
                        <span className="label-for-otvorene">
                          Izlistaj otvorene postaje
                        </span>
                        <span className="" aria-hidden="true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            role="img"
                            aria-labelledby="otvori-otvorene"
                            focusable="false"
                          >
                            <title id="otvori-otvorene">Locirajte se</title>
                            <defs>
                              <style>
                                .a
                                {
                                  "{fill:none;stroke:#57a4ff;stroke-linecap:square;stroke-width:2px;}"
                                }
                                .b{"{fill:#57a4ff;stroke:none;}"}
                              </style>
                            </defs>
                            <circle
                              className="a"
                              cx="13"
                              cy="13"
                              r="13"
                              transform="translate(3 3)"
                            />
                            <line
                              className="a"
                              y2="5"
                              transform="translate(16 1)"
                            />
                            <line
                              className="a"
                              x1="5"
                              transform="translate(26 16)"
                            />
                            <line
                              className="a"
                              y1="5"
                              transform="translate(16 26)"
                            />
                            <line
                              className="a"
                              x2="5"
                              transform="translate(1 16)"
                            />
                            <circle
                              className="b"
                              cx="3"
                              cy="3"
                              r="3"
                              transform="translate(13 13)"
                            />
                          </svg>
                        </span>
                      </button> */}
                    </div>
                    <section className="trazi-opcije">
                      <div className="trazivrstagoriva">
                        <label
                          className="trazivrstagoriva-label"
                          htmlFor="trazivrstagoriva"
                        >
                          –Vrsta goriva–
                        </label>
                        <select
                          id="trazivrstagoriva"
                          name="tipGoriva"
                          required
                          value={filterArgs.tipGoriva}
                          onChange={onChange}
                        >
                          {/* <option value=""></option> */}
                          {Object.values(tip_gorivas).map(tipGoriva => (
                            <option key={tipGoriva.id} value={tipGoriva.id}>
                              {tipGoriva.tip_goriva}
                            </option>
                          ))}
                          {/* <option value="1benz">Benzinska goriva</option>
              <option value="2diz">Dizelska goriva</option>
              <option value="3autop">Autoplin</option>
              <option value="4pulje">Plinsko ulje</option>
              <option value="5biog">Biogoriva</option>
              <option value="6ukap">Ukapljeni naftni plin u bocama</option> */}
                        </select>
                      </div>
                      <div className="traziradius">
                        <label
                          className="traziradius-label"
                          htmlFor="traziradius"
                        >
                          –Radius–
                        </label>
                        <select
                          id="traziradius"
                          name="radius"
                          required
                          value={filterArgs.radius}
                          onChange={onChange}
                        >
                          {/* <option value=""></option> */}
                          <option value="5">5 km</option>
                          <option value="10">10 km</option>
                          <option value="15">15 km</option>
                          <option value="25">25 km</option>
                          <option value="50">50 km</option>
                        </select>
                      </div>
                      <a
                        href="#trazilica-filtri-jump"
                        id="otvori-filtre"
                        className="otvori-filtre"
                        aria-label="Filter"
                      >
                        <span className="icon-svg" aria-hidden="true">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            role="img"
                            aria-labelledby="icon-postavke"
                            focusable="false"
                          >
                            <title id="icon-postavke">Filter</title>
                            <defs>
                              <style>
                                .prefa
                                {
                                  "{fill:none;stroke:#878a90;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:2px;}"
                                }
                              </style>
                            </defs>
                            <g transform="translate(0.5 0.5)">
                              <line
                                className="prefa"
                                x2="4"
                                transform="translate(11.5 3.5)"
                              />
                              <line
                                className="prefa"
                                x2="2"
                                transform="translate(0.5 3.5)"
                              />
                              <line
                                className="prefa"
                                x1="4"
                                transform="translate(0.5 12.5)"
                              />
                              <line
                                className="prefa"
                                x1="2"
                                transform="translate(13.5 12.5)"
                              />
                              <circle
                                className="prefa"
                                cx="3"
                                cy="3"
                                r="3"
                                transform="translate(2.5 0.5)"
                              />
                              <circle
                                className="prefa"
                                cx="3"
                                cy="3"
                                r="3"
                                transform="translate(7.5 9.5)"
                              />
                            </g>
                          </svg>
                        </span>
                        <span className="label-for-filter-open">Filter</span>
                      </a>
                      <span id="trazilica-filtri-jump"></span>
                      <section
                        id="trazilica-filtri"
                        className="trazilica-filtri"
                      >
                        <h2>Filter</h2>
                        <a
                          // href="#trazilica-otvorene-jump"
                          href="#main-nav-open"
                          className="zatvori-filtre"
                          aria-label="Natrag"
                        >
                          <span className="label-for-filter-toggle">
                            Natrag
                          </span>
                          <span className="" aria-hidden="true">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7.2"
                              height="11.4"
                              viewBox="0 0 7.2 11.4"
                              role="img"
                              aria-hidden="true"
                              focusable="false"
                            >
                              <defs>
                                <style>.arra{"{fill:#2c313c;}"}</style>
                              </defs>
                              <g transform="translate(0)">
                                <path
                                  className="arra"
                                  d="M11.1,13.7l1.5-1.4L8.3,8l4.3-4.3L11.1,2.3,5.4,8Z"
                                  transform="translate(-5.4 -2.3)"
                                />
                              </g>
                            </svg>
                          </span>
                        </a>
                        <ul
                          className="filter-opcije-nav"
                          role="tablist"
                          aria-controls="trazilica-filtri"
                        >
                          <li
                            role="tab"
                            aria-controls="filter-vg"
                            tabIndex="0"
                            aria-selected="true"
                          >
                            Vrste goriva
                          </li>
                          <li
                            role="tab"
                            aria-controls="filter-op"
                            tabIndex="0"
                            aria-selected="false"
                          >
                            Opcije postaje
                          </li>
                          <li
                            role="tab"
                            aria-controls="filter-rv"
                            tabIndex="0"
                            aria-selected="false"
                          >
                            Radno vrijeme
                          </li>
                          <li
                            role="tab"
                            aria-controls="filter-ob"
                            tabIndex="0"
                            aria-selected="false"
                          >
                            Obveznici
                          </li>
                        </ul>
                        <div
                          className="tabs-content"
                          role="tabpanel"
                          aria-expanded="true"
                        >
                          <section
                            id="filter-vg"
                            role="tabpanel"
                            aria-expanded="true"
                          >
                            <h3>Vrste goriva</h3>
                            {Object.values(vrsta_gorivas).map(vrstaGoriva => (
                              <div key={vrstaGoriva.id}>
                                <input
                                  type="checkbox"
                                  name="vrstaGoriva"
                                  id={"vg-" + vrstaGoriva.id}
                                  value={vrstaGoriva.id}
                                />
                                <label htmlFor={"vg-" + vrstaGoriva.id}>
                                  {vrstaGoriva.vrsta_goriva}
                                </label>
                              </div>
                            ))}
                            {/* <input
                  type="checkbox"
                  name="vrsteGoriva"
                  id="vg-es95sa"
                  value="1"
                />
                <label htmlFor="vg-es95sa">Eurosuper 95 s aditivima</label> */}
                          </section>
                          <section
                            id="filter-op"
                            role="tabpanel"
                            aria-expanded="false"
                          >
                            <h3>Opcije postaje</h3>
                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-zamjena-ulja"
                              value={1}
                            />
                            <label htmlFor="op-zamjena-ulja">
                              Zamjena motornoga ulja
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-autopraonica"
                              value={2}
                            />
                            <label htmlFor="op-autopraonica">
                              Autopraonica
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-opskrbaPlovila"
                              value={3}
                            />
                            <label htmlFor="op-opskrbaPlovila">
                              Opskrba plovila
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-wifi"
                              value={4}
                            />
                            <label htmlFor="op-wifi">Hot spot/Wifi</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-bankomat"
                              value={5}
                            />
                            <label htmlFor="op-bankomat">Bankomat</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-wc"
                              value={6}
                            />
                            <label htmlFor="op-wc">WC</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-mjenjacnica"
                              value={7}
                            />
                            <label htmlFor="op-mjenjacnica">Mjenjačnica</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-trgovina"
                              value={8}
                            />
                            <label htmlFor="op-trgovina">Trgovina</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-restoran"
                              value={9}
                            />
                            <label htmlFor="op-restoran">Restoran</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-caffeBar"
                              value={10}
                            />
                            <label htmlFor="op-caffeBar">Caffe bar</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-pekarskiProizvodi"
                              value={11}
                            />
                            <label htmlFor="op-pekarskiProizvodi">
                              Pekarski proizvodi
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-wc-invalide"
                              value={12}
                            />
                            <label htmlFor="op-wc-invalide">
                              WC za invalide
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-previjalisteBebe"
                              value={13}
                            />
                            <label htmlFor="op-previjalisteBebe">
                              Previjalište za bebe
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-tus"
                              value={14}
                            />
                            <label htmlFor="op-tus">Tuš</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-igraonica"
                              value={15}
                            />
                            <label htmlFor="op-igraonica">
                              Dječje igralište/Igraonica
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-hotel"
                              value={16}
                            />
                            <label htmlFor="op-hotel">Hotel/Motel</label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-parkingAutobuse"
                              value={17}
                            />
                            <label htmlFor="op-parkingAutobuse">
                              Parking za autobuse
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-kucniLjubimci"
                              value={18}
                            />
                            <label htmlFor="op-kucniLjubimci">
                              Mjesto za kućne ljubimce
                            </label>

                            <input
                              type="checkbox"
                              name="opcija"
                              id="op-24sata"
                              value={19}
                            />
                            <label htmlFor="op-24sata">Otvoreno 24 sata</label>
                            {/* {data.opcije.array.map(opcija => (
                  <div key={opcija.id}>
                    <input
                      type="checkbox"
                      name="opcija"
                      id={"op-" + opcija.id}
                      value={opcija.id}
                    />
                    <label htmlFor={"op-" + opcija.id}>{opcija.naziv}</label>
                  </div>
                ))} */}
                          </section>
                          <section
                            id="filter-rv"
                            role="tabpanel"
                            aria-expanded="false"
                          >
                            <h3>Radno vrijeme</h3>
                            <fieldset>
                              <legend>Dan u tjednu</legend>
                              <input
                                type="checkbox"
                                name="danUTjednu"
                                id="rvd-ponedjeljak"
                                value={1}
                              />
                              <label htmlFor="rvd-ponedjeljak">
                                Ponedjeljak
                              </label>
                              <input
                                type="checkbox"
                                name="danUTjednu"
                                id="rvd-utorak"
                                value={2}
                              />
                              <label htmlFor="rvd-utorak">Utorak</label>
                              <input
                                type="checkbox"
                                name="danUTjednu"
                                id="rvd-srijeda"
                                value={3}
                              />
                              <label htmlFor="rvd-srijeda">Srijeda</label>
                              <input
                                type="checkbox"
                                name="danUTjednu"
                                id="rvd-cetvrtak"
                                value={4}
                              />
                              <label htmlFor="rvd-cetvrtak">Četvrtak</label>
                              <input
                                type="checkbox"
                                name="danUTjednu"
                                id="rvd-petak"
                                value={5}
                              />
                              <label htmlFor="rvd-petak">Petak</label>
                              <input
                                type="checkbox"
                                name="danUTjednu"
                                id="rvd-subota"
                                value={6}
                              />
                              <label htmlFor="rvd-subota">Subota</label>
                              <input
                                type="checkbox"
                                name="danUTjednu"
                                id="rvd-nedjelja"
                                value={0}
                              />
                              <label htmlFor="rvd-nedjelja">Nedjelja</label>
                            </fieldset>
                            <fieldset>
                              <legend>Vrijeme</legend>
                              <input
                                type="checkbox"
                                name="radnoVrijeme"
                                id="rv-0024"
                                value="00:00:00-24:00:00"
                              />
                              <label htmlFor="rv-0024">00:00 - 24:00</label>
                              <input
                                type="checkbox"
                                name="radnoVrijeme"
                                id="rv-0622"
                                value="06:00:00-22:00:00"
                              />
                              <label htmlFor="rv-0622">06:00 - 22:00</label>
                            </fieldset>
                          </section>
                          <section
                            id="filter-ob"
                            role="tabpanel"
                            aria-expanded="false"
                          >
                            <h3>Obveznici</h3>
                            {Object.values(obvezniks).map(obveznik => (
                              <div key={obveznik.id} className="col">
                                <input
                                  type="checkbox"
                                  name="obveznik"
                                  id={"obv-" + obveznik.id}
                                  value={obveznik.id}
                                />
                                <label htmlFor={"obv-" + obveznik.id}>
                                  {obveznik.naziv}
                                </label>
                              </div>
                            ))}
                            {/* <input
                  type="checkbox"
                  name="obveznici"
                  id="obv-tifon"
                  value="5"
                />
                <label htmlFor="obv-tifon">Tifon d.o.o.</label> */}
                          </section>
                        </div>
                        <div className="form-submit">
                          {/* <button value="Resetiraj filter"></button> */}
                          <input
                            type="reset"
                            value="Resetiraj filter"
                          // onClick={() => {
                          //   window.location = "#trazilica-otvorene-jump";
                          // }}
                          />
                          <input
                            type="submit"
                            value="Primijeni filter"
                            onClick={handleSubmitFilter}
                          />
                        </div>
                      </section>
                    </section>
                  </form>
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
      </div>
      {/* <div id="toggle" onClick={this}>
        <div className="one"></div>
      </div> */}

      {/* <span id="trazilica-otvorene-jump"></span> */}
      <span id="main-nav-open"></span>
      {/* <TrazilicaRezultatiOtvorene
        postajas={postajas}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      /> */}
      <TrazilicaRezultatiOtvorene2
        postajas={postajas}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </section>
  );
}

const onToggleClick = e => {
  e.toggleClass("on");
  // document.getElementById("trazilica-toggle").slideToggle();
};

export default TrazilicaObrazac;
