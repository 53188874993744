import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import { DataContext } from "../App";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function Header(props) {
  const { currentPath, userLogged, username } = props;
  const addClassNameIfCurrPath = path =>
    path === currentPath ? "navbar-current" : "";
  const { postajas } = useContext(DataContext);

  const [width] = useWindowSize();
  const [navBarOpen, setNavBarOpen] = useState();
  useEffect(() => {
    setNavBarOpen(width < 880 ? false : true);
  }, [width]);
  const onClickCallback = () => {
    setNavBarOpen(width < 880 ? false : true);
  };

  return (
    <header role="banner">
      <a href="/" className="logo-min2go">
        <img
          src="./mzoe-gor.hr.png"
          alt="mzoe-gor"
          style={{ width: "4.5em" }}
        />
      </a>
      <a
        href="#main-nav"
        // id="main-nav-open"
        className="navbar-open"
        aria-label="Otvori navigaciju"
        onClick={() => setNavBarOpen(true)}
      >
        <span className="label-for-main-nav">Otvori navigaciju</span>
        <span className="" aria-hidden="true">
          &#9776;
        </span>
      </a>
      {navBarOpen && (
        <nav id="main-nav" className="navbar" aria-label="Navigacija">
          <h2>Navigacija</h2>
          <a
            href="#main-nav-open"
            className="navbar-close"
            aria-label="Zatvori navigaciju"
            onClick={() => setNavBarOpen(false)}
          >
            <span className="label-for-main-nav-toggle">
              Zatvori navigaciju
            </span>
            <span className="" aria-hidden="true">
              &times;
            </span>
          </a>
          <ul className="navbar-ul">
            <li className="navbar-item">
              <Link
                to="/"
                className={"navbar-link " + addClassNameIfCurrPath("/")}
                onClick={onClickCallback}
              >
                Naslovnica
              </Link>
            </li>
            {postajas.map(postaja => (
              <li key={postaja.id}>
                {/* <Link to={`/${postaja.id}/${postaja.obveznik.naziv}-${postaja.naziv}`}></Link> */}
                <Link to={postaja.url}></Link>
              </li>
            ))}
            <li className="navbar-item">
              <Link
                to="/obveznici"
                className={
                  "navbar-link " + addClassNameIfCurrPath("/obveznici")
                }
                onClick={onClickCallback}
              >
                Obveznici
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/kontakt"
                className={"navbar-link " + addClassNameIfCurrPath("/kontakt")}
                onClick={onClickCallback}
              >
                Kontakt
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/info"
                className={"navbar-link " + addClassNameIfCurrPath("/info")}
                onClick={onClickCallback}
              >
                Upute
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/projekt"
                className={"navbar-link " + addClassNameIfCurrPath("/projekt")}
                onClick={onClickCallback}
              >
                O Projektu
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/privatnost"
                className={
                  "navbar-link " + addClassNameIfCurrPath("/privatnost")
                }
                onClick={onClickCallback}
              >
                Pravila privatnosti
              </Link>
            </li>
            {userLogged && (
              <li className="navbar-item">
                <Link
                  to="/prijava-greske"
                  className={
                    "navbar-link " + addClassNameIfCurrPath("/prijava-greske2")
                  }
                  onClick={onClickCallback}
                >
                  Prijava greške
                </Link>
              </li>
            )}
            {!userLogged && (
              <li className="navbar-item">
                <Link
                  to="/prijava"
                  className="navbar-prijava"
                  onClick={onClickCallback}
                >
                  Prijava
                </Link>
              </li>
            )}
            {!userLogged && (
              <li className="navbar-item">
                <Link
                  to="/registracija"
                  className="navbar-registracija"
                  onClick={onClickCallback}
                >
                  Registracija
                </Link>
              </li>
            )}
            {userLogged && (
              <li className="navbar-item">
                <Link
                  to="#"
                  className="navbar-prijava"
                  onClick={onClickCallback}
                >
                  {username}
                </Link>
              </li>
            )}
            {userLogged && (
              <li className="navbar-item">
                <Link
                  to="/odjava"
                  className="navbar-registracija"
                  onClick={onClickCallback}
                >
                  Odjava
                </Link>
              </li>
            )}
            <li>
              <Link to="/resetiranje-lozinke"></Link>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default Header;
