export const compareFunction = (postaja1, postaja2) => {
  if (postaja1.open && !postaja2.open) {
    return -1;
  } else if (!postaja1.open && postaja2.open) {
    return 1;
  }

  if (postaja1.cijeneGoriva[0].cijena > postaja2.cijeneGoriva[0].cijena) {
    return 1;
  } else if (
    postaja1.cijeneGoriva[0].cijena < postaja2.cijeneGoriva[0].cijena
  ) {
    return -1;
  }

  if (postaja1.udaljenost > postaja2.udaljenost) {
    return 1;
  } else if (postaja1.udaljenost < postaja2.udaljenost) {
    return -1;
  }

  return 0;
};

export const compareFunction2 = (postaja1, postaja2) => {
  if (postaja1.open && !postaja2.open) {
    return -1;
  } else if (!postaja1.open && postaja2.open) {
    return 1;
  }

  // if (postaja1.cijeneGoriva[0].cijena > postaja2.cijeneGoriva[0].cijena) {
  //   return 1;
  // } else if (
  //   postaja1.cijeneGoriva[0].cijena < postaja2.cijeneGoriva[0].cijena
  // ) {
  //   return -1;
  // }

  if (postaja1.udaljenost > postaja2.udaljenost) {
    return 1;
  } else if (postaja1.udaljenost < postaja2.udaljenost) {
    return -1;
  }

  return 0;
};
