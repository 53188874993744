import React from "react";

function NavAccess(props) {
  return (
    <ul className="nav-access">
      <li>
        <a href="#main-content" className="skip-main skip-link">
          Preskoči do glavnog sadržaja
        </a>
      </li>
      <li>
        <a
          href="#trazilica"
          className="skip-search skip-link element-focusable"
        >
          Preskoči na pretraživanje
        </a>
      </li>
    </ul>
  );
}

export default NavAccess;
