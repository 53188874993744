import React from "react";
import CijeneEmailomObrazac from "./CijeneEmailomObrazac2";

function Footer(props) {
  return (
    <footer role="contentinfo">
      <CijeneEmailomObrazac />
      <p>
        <a href="/">
          {/* <img src="./min2goLogo.png" alt="Min2Go" /> */}
          <img
            src="./mzoe-gor.hr.png"
            alt="mzoe-gor"
            style={{ width: "6em" }}
          />
        </a>
        <br />
        Copyright © <a href="https://mzoe.gov.hr/">MZOE</a> 2020
      </p>
    </footer>
  );
}

export default Footer;
