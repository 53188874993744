import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import notifier from "simple-react-notifications";

import { AuthContext } from "../App";

function Odjava(props) {
  const { signOut } = useContext(AuthContext);
  notifier.success("Uspješno ste se odjavili.", {
    autoClose: true,
    autoClose: 3000
  });
  signOut();
  return null;
  // return <Redirect to="/" />;
}

export default Odjava;
