import React, { useEffect, useState, useContext } from "react";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import notifier from "simple-react-notifications";
import Spinner from "./Spinner";

import { AuthContext, LoadingContext } from "../App";

function Prijava(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { login } = useContext(AuthContext);

  const { setLoading } = useContext(LoadingContext);
  const [redirect, setRedirect] = useState(false);

  const createHandleFormChange = (formData, setFormData) => {
    return event => {
      const target = event.target;
      setFormData({ ...formData, [target.name]: target.value });
    };
  };

  const [loginFormData, setLoginFormData] = useState({
    username: "",
    password: ""
  });
  const handleLoginFormChange = createHandleFormChange(
    loginFormData,
    setLoginFormData
  );
  const handleLoginFormSubmit = event => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/auth`, loginFormData)
      .then(function(response) {
        if (response.data.access_token) {
          notifier.success("Uspješno ste se prijavili.", {
            autoClose: true,
            autoClose: 3000
          });
          setLoading(false);
          login(loginFormData.username, response.data.access_token);
        } else {
          setLoading(false);
          notifier.error(response.data.msg);
        }
      })
      .catch(function(error) {
        console.log(error);
        setLoading(false);
        notifier.error(
          `Dogodila se greška prilikom prijave. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${process.env.REACT_APP_EMAIL}.`
        );
      });
  };

  const [resetFormData, setResetFormData] = useState({
    email: ""
  });
  const handleResetFormChange = createHandleFormChange(
    resetFormData,
    setResetFormData
  );
  const handleResetFormSubmit = event => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/reset`, resetFormData)
      .then(function(response) {
        setLoading(false);
        if (response.data.ok) {
          notifier.success(
            "Poruka s linkom za resetiranje lozinke računa je poslana na vašu e-adresu.",
            {
              autoClose: true,
              autoClose: 3000
            }
          );
          setRedirect(true);
        } else {
          notifier.error(response.data.msg);
        }
      })
      .catch(function(error) {
        console.log(error);
        setLoading(false);
        notifier.error(
          `Dogodila se greška prilikom resetiranja lozinke. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${process.env.REACT_APP_EMAIL}.`
        );
      });
  };

  const [resendFormData, setResendFormData] = useState({
    email: ""
  });
  const handleResendFormChange = createHandleFormChange(
    resendFormData,
    setResendFormData
  );
  const handleResendFormSubmit = event => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/resend`, resendFormData)
      .then(function(response) {
        setLoading(false);
        if (response.data.ok) {
          setRedirect(true);
          notifier.success(
            "Poruka s linkom za aktivaciju računa je poslana na vašu e-adresu.",
            {
              autoClose: true,
              autoClose: 3000
            }
          );
        } else {
          notifier.info(response.data.msg);
        }
      })
      .catch(function(error) {
        console.log(error);
        setLoading(false);
        notifier.error(
          `Dogodila se greška prilikom slanja potvrde na vašu e-adresu. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${process.env.REACT_APP_EMAIL}.`
        );
      });
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <article id="main-content" className="cijene-emailom-obrazac">
      <h2 className="topmargin">Prijava</h2>
      <form className="cijene-mailom" onSubmit={handleLoginFormSubmit}>
        <div className="form-items prijava">
          <div className="form-item">
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Korisničko ime"
              // pattern=".+@globex.com"

              required
              value={loginFormData.username}
              onChange={handleLoginFormChange}
            />
            <label htmlFor="username">Korisničko ime</label>
          </div>
        </div>
        <div className="form-items prijava">
          <div className="form-item ">
            <input
              type="password"
              id="zaporka"
              name="password"
              placeholder="Lozinka"
              // pattern=".+@globex.com"

              required
              value={loginFormData.password}
              onChange={handleLoginFormChange}
            />
            <label htmlFor="email">Lozinka</label>
          </div>
        </div>
        <div className="form-submit">
          <input type="submit" value="Prijava" />
        </div>
        <div>
          <a href="#resetlozinke">Zaboravljena lozinka?</a>
        </div>
        <div>
          <a href="#potvrda">Niste primili email potvrdu?</a>
        </div>
      </form>
      <div id="resetlozinke"></div>
      <h2 className="topmargin">Resetiranje lozinke</h2>
      <form className="cijene-mailom" onSubmit={handleResetFormSubmit}>
        <div className="form-items">
          <div className="form-item">
            <input
              type="text"
              id="email"
              name="email"
              placeholder="E-adresa"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              required
              value={resetFormData.email}
              onChange={handleResetFormChange}
            />
            <label htmlFor="email">E-adresa</label>
          </div>
        </div>
        <div className="form-submit">
          <input type="submit" value="Resetiranje lozinke" />
        </div>
      </form>

      <div id="potvrda"></div>
      <h2 className="topmargin">Potvrda računa</h2>
      <form className="cijene-mailom" onSubmit={handleResendFormSubmit}>
        <div className="form-items">
          <div className="form-item">
            <input
              type="text"
              id="email"
              name="email"
              placeholder="E-adresa"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              required
              value={resendFormData.email}
              onChange={handleResendFormChange}
            />
            <label htmlFor="email">E-adresa</label>
          </div>
        </div>
        <div className="form-submit">
          <input type="submit" value="Potvrda računa" />
        </div>
      </form>
    </article>
  );
}

export default withRouter(Prijava);
