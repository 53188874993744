import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CoordsContext, MapContext } from "../App";

import { getCurrentDay, getCurrentTime } from "./tmp/util";
import { createAddOpenConsumer } from "./tmp/postajaConsumers";
import { getDistanceAndTimeOfTwoLocations } from "./RoutingMachine";

import GorivaPostaje from "../widgets/GorivaPostaje";

function Postaja(props) {
  const { postaja, showDetails, onMouseEnter, onMouseLeave } = props;

  const currentDay = getCurrentDay();
  const currentTime = getCurrentTime();
  createAddOpenConsumer(currentDay, currentTime)(postaja);

  let dataPostajaRv, postajaRvDt;
  dataPostajaRv = postajaRvDt = "Zatvoreno";
  if (postaja.open) {
    dataPostajaRv = postajaRvDt = "Otvoreno";
  }

  const [distance, setDistance] = useState(`${postaja.udaljenost} km `);
  const [time, setTime] = useState(null);
  const { coords } = useContext(CoordsContext);
  const map = useContext(MapContext);
  useEffect(() => {
    return;
    if (!coords) {
      return;
    }

    let didCancel = false;

    getDistanceAndTimeOfTwoLocations(
      { long: postaja.long, lat: postaja.lat },
      { lat: coords.latitude, lng: coords.longitude },
      map,
      e => {
        if (didCancel) {
          return;
        }

        var routes = e.routes;
        var summary = routes[0].summary;
        var km = Math.round(summary.totalDistance / 1000);
        setDistance(`${km} km `);
        var h = Math.floor(summary.totalTime / 3600);
        var min = Math.round((summary.totalTime % 3600) / 60);
        setTime((h !== 0 ? `${h}h ` : "") + `${min} min`);
      }
    );

    return () => {
      didCancel = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let duration;
  if (postaja.duration) {
    var h = Math.floor(postaja.duration / 3600);
    var min = Math.round((postaja.duration % 3600) / 60);
    duration = (h !== 0 ? `${h}h ` : "") + `${min} min`;
  }
  return (
    <article className="postaja">
      <Link
        to={postaja.url}
        className="postaja-link"
        onMouseEnter={onMouseEnter && (e => onMouseEnter(postaja.id))}
        onMouseLeave={onMouseLeave && (e => onMouseLeave())}
      >
        <h3>{postaja.naziv}</h3>
        {postaja.obveznik.logo ? (
          <img
            className="logo-obveznika"
            src={process.env.REACT_APP_LOGO_URL_PREFIX + postaja.obveznik.logo}
            alt={postaja.obveznik.naziv}
          ></img>
        ) : (
            <h6 className="mala-margina">{postaja.obveznik.naziv}</h6>
          )}
        {/* <dl className="postaja-rv" data-postaja-rv={dataPostajaRv}>
          <dt>{postajaRvDt}</dt>
          {postaja.radnoVrijemesDanas &&
            postaja.radnoVrijemesDanas.map(rv => (
              <dd key={rv.id}>
                <time key={1}>{rv.pocetak.slice(0, 5)}</time>–
                <time key={2}>{rv.kraj.slice(0, 5)}</time>
              </dd>
            ))}
        </dl> */}
        <dl className="postaja-rv" data-postaja-rv={dataPostajaRv}>
          <dd><span>Radno vrijeme:</span></dd>
          {postaja.radnoVrijemesDanas &&
            postaja.radnoVrijemesDanas.map(rv => (
              <dd key={rv.id}>
                <time key={1}>{rv.pocetak.slice(0, 5)}</time>–
                <time key={2}>{rv.kraj.slice(0, 5)}</time>
              </dd>
            ))}
          <br />
          <dt>{dataPostajaRv}</dt>
        </dl>
        <address className="postaja-lokacija">
          <ul>
            <li>{postaja.adresa + ", " + postaja.mjesto}</li>
            <li className="postaja-proximity">
              {/* {postaja.distance && `${Math.round(postaja.distance)} km `} */}
              {distance}
              <small>
                <time dateTime="PT14M">{time}</time>
                {/* <time dateTime="PT14M">{duration}</time> */}
              </small>
            </li>
          </ul>
        </address>
        <GorivaPostaje postaja={postaja} />
        {showDetails && <div aria-hidden="true">Detalji</div>}
      </Link>
    </article>
  );
}

export default Postaja;
